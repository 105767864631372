.MenuItem {
  display: block;
  background-color: body-color;
  border-top: rem(1px) solid #d3d3d3;
  border-right: @border-top;
  &:last-child {
    border-bottom: rem(1px) solid #d3d3d3;
  }

  > .title {
    padding: rem(15px) rem(20px);
    color: text-color;
    text-transform: uppercase;
    font-size: rem(17px);
    line-height: rem(35px);
  }

  > .title.-current {
    
  }

  > .title:not(.-current) {
    cursor: pointer;
    &:hover {
      color: action-color;
    }
  }
}
