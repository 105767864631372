.App {
  > .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: rem(60px);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(2px) rem(16px);
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.30);
  }

  > .header > .icons {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  > .content {
    max-width: rem( 600px );
    min-height: calc(100vh - 64px - 60px);
    margin: rem(60px + 25px) auto;
  }

  > .footer {
    padding: rem(16px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: darkgray;
    >.branding-logo {
      margin: rem(32px) 0;
    }
  }

  > .menu {
    position: fixed;
    top: rem(60px);
    width: rem(70px);
    height: @css{ calc(100vh - } rem(60px) @css{ ) };
    transition: transform 0.2s ease-in-out;
    overflow: auto;
    z-index: 20;
    &.-open {
      transform: none;
    }
    &.-closed {
      transform: translateX(-100%);
    }
  }

  .menu-button {

      box-sizing: content-box;
      width: rem(33px);
      height: rem(27px);
      padding-left: rem(8px);
      padding-right: @padding-left;

      &:hover {
        fill: action-color;
      }
  }
}
