.LocaleLink {
  text-transform: uppercase;

  &.-active {
    color: text-color;
  }
}

.LocaleLink + .LocaleLink {
  margin-left: rem(10px);
}
