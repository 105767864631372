

html, body {
    font-family: futura-pt, sans-serif;
    color: text-color;
}

h1 {
    
    font-size: rem(48px);
    line-height: rem(58px);
}

h2 {
    font-size: rem(32px);
    line-height: rem(42px);
}

h3 {
    font-size: rem(26px);
    line-height: rem(34px);
}

p {
    font-size: rem(19px);
    line-height: rem(25px);
}

a {
    color: action-color;

    &:hover {
        color: text-color;
    }
}